import * as React from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from '@src/shared/src/actions';
import { API_URL } from '@src/shared/src/const/api';
import { t } from '@toolkit/util/i18n';
import {notifyError} from "@toolkit/util/app";
import {errorLogger} from "@src/shared/src/util/errors";

type Props = {
    os: "android" | "ios"
}

const MobileAuthConn: React.FC<Props> = ({ os }) => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<any>();

  React.useEffect(() => {
    dispatch(
      authActions.mobileAuthAsync.request({
        onSuccess: setState,
          onError: () => {
          errorLogger(
              `Failed to fetch cookie`,
              new Error(`Could not fetch cookie for ${os} user.`)
          );
          notifyError(['global.somethingWrong']);
      }
      }),
    );
  }, []);

    if (state) {
    return (
      <>
        <h2>{t('mobileAuthConn.title.confirm_user')}</h2>
          <p>{t('mobileAuthConn.text.confirm_user')}</p>
          <table>
              <tr>
                  <th>{t('mobileAuthConn.text.organization')}</th>
                  <th>{t('mobileAuthConn.text.name')}</th>
              </tr>
              <tr>
                  <td>{state.org}</td>
                  <td>{state.user_name}</td>
                  <td>
                      <div>
                          {(() => {
                              //since android will preload get links, it needs a post request deep link to hide the cookie from the logs
                              //iOS on the other hand does not support post requests as deep links but luckily it does not preload so nothing in the logs
                              if (os == 'ios'){
                                  return (
                                      <a className="button" target="_blank" href={`${API_URL.DEFAULT}/mobile_auth?json_cookie=${state.json_cookie}`}>
                                          {t('mobileAuthConn.text.confirm')}
                                      </a>
                                  )
                              } return (
                                  <form method="post" action={`${API_URL.DEFAULT}/mobile_auth?json_cookie=${state.json_cookie}`} className="inline" target="_blank">
                                      <button type="submit" className="button">
                                          {t('mobileAuthConn.text.confirm')}
                                      </button>
                                  </form>
                              );
                          })()}
                      </div>
                  </td>
              </tr>
          </table>
      </>
    );
  }

  return <h1>Loading...</h1>
};

export default MobileAuthConn;
