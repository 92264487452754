import * as React from 'react';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';

// Utils
// Constants
// Services
// Models, Stores & Interfaces
// Components
import {
  ForgotPasswordConn,
  LoginConn,
  ResetPasswordPage,
  TempLoginConn,
  MobileAuthConn,
} from '@pod/admin_user';

export const AuthRouter: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div>
      <Route path={`${match.url}/callback`} render={() => <Redirect to="/" />} />
      <Route path={`${match.url}/login`} component={LoginConn} />
      <Route path={`${match.url}/support/login`} component={TempLoginConn} />
      <Route path={`${match.url}/forgot_password`} component={ForgotPasswordConn} />
      <Route path={`${match.url}/reset_password`} component={ResetPasswordPage} />
      <Route path={`${match.url}/mobile/ios`} render={() => <MobileAuthConn os="ios" />}  />
      <Route path={`${match.url}/mobile/android`} render={() => <MobileAuthConn os="android" />}  />
      <Route exact path={`${match.url}/`} component={LoginConn} />
    </div>
  );
};

export default AuthRouter;
